<template>
  <div class="person-center">
    <v-head headType="person" />
    <div class="person-container" id="personContainer">
      <div class="person-center-view">
        <el-scrollbar class="comprehensive-box">
          <router-view></router-view>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>
<script>
import Head from './components/head'
export default {
  data() {
    return {
      sideBar: true
    }
  },
  components: {
    'v-head': Head
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'min-width: inherit;')
    document.querySelector('html').setAttribute('style', 'min-width: inherit;')
  },
  beforeDestroy() {
    document.querySelector('body').setAttribute('style', '')
    document.querySelector('html').setAttribute('style', '')
  },
  methods: {},
  watch: {
    $route: {
      handler(to) {
        if (to.path === '/personHome') {
          this.sideBar = true
        } else {
          this.sideBar = false
        }
      },
      immediate: true
    }
  }
}
</script>
<style scoped lang="scss">
.person-center {
  height: 100vh;
  background-color: #f1f3f7;
  ::v-deep.el-scrollbar__wrap {
    overflow-x: hidden;
    background-color: #f1f3f7;
    // padding: 32px 64px;
  }
  ::v-deep.el-scrollbar__view {
    height: 100%;
  }
  ::v-deep .shadowBox {
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }
  .person-container {
    display: block;
    height: 100%;
    padding-top: 59px;
  }

  .person-sideBar {
    background: #fff;
    width: 192px;
    border-right: 1px solid #c3cada;
    padding: 16px 0;
    font-size: 16px;
    color: #2a2b2c;
    line-height: 50px;
    flex-shrink: 0;
    position: relative;
    .tt {
      height: 48px;
      width: 100%;
      box-sizing: border-box;
      font-size: 16px;
      color: #2a2b2c;
      padding-left: 25px;
      cursor: pointer;
      border-left: 2px transparent solid;
      .svg-icon {
        font-size: 18px;
        margin-right: 10px;
        color: #9fa3ab;
      }
      &.active {
        color: $primaryColor;
        background: rgba(72, 115, 246, 0.1);
        border-left: 2px $primaryColor solid;
        .svg-icon {
          color: $primaryColor;
        }
      }
    }
  }

  .person-center-view {
    height: 100%;
    flex: 1;

    .title {
      line-height: 68px;
      height: 68px;
      margin: 0;
      border-bottom: 1px solid #c3cada;
      background: #fff;
      font-size: 14px;
      color: #303b50;
      font-weight: 700;
      padding-left: 32px;
    }
  }
  .comprehensive-box {
    height: 100%;
  }
}
.userContainer {
  height: calc(100% - 58px);
}
</style>
<style lang="scss">
.person-center {
  // .el-button {
  //   flex-shrink: 0;
  //   border-radius: 2px;
  //   font-size: 14px;
  //   line-height: 32px;
  //   padding: 0 24px;
  // }
}
.tableListBox {
  background-color: #fff;
  padding: 12px 32px;
  .list {
    .el-tabs__item.is-active {
      color: $primaryColor;
    }
    .el-tabs__active-bar {
      background-color: $primaryColor;
    }
    .el-tabs__nav-wrap::after {
      background-color: #dee0e9;
    }
    .el-table {
      td,
      th.is-leaf {
        border-bottom: 1px solid #dee0e9;
      }
      .notInvoiceBtn {
        width: 58px;
      }
      .cell {
        padding-left: 24px;
        color: #2a2b2c;
      }
      tr > th {
        background-color: #f7faff;
        border-radius: 4px 4px 0px 0px;
        padding: 7px 0;
        .cell {
          font-size: 14px;
          color: #6c737b;
          font-weight: 500;
        }
      }
    }

    .el-button--text {
      padding: 0px;
      margin-right: 24px;
      margin-left: 0;
    }
  }
  .statusColor {
    height: 24px;
    line-height: 22px;
    display: inline-block;
    padding: 0 8px;
    color: #e89e1c;
    background: #fef8ec;
    border-radius: 15px;
    border: 1px solid #fbbc4d;
    // box-sizing: content-box;
    &.statusColor2 {
      color: #1c67f3;
      background: #dbe4ff;
      border: 1px solid #a9beff;
    }
    &.statusColor11,
    &.certStatusColor1 {
      color: #52c41a;
      background: #eff9eb;
      border: 1px solid #d4f0c8;
    }
    &.statusColor3,
    &.statusColor13,
    &.certStatusColor-1,
    &.certStatusColor-4,
    &.certStatusColor-5 {
      color: #e34d59;
      background: #ffeeee;
      border: 1px solid #f7caca;
    }
    &.statusColor12,
    &.certStatusColor-2,
    &.certStatusColor-3,
    &.certStatusColor-6,
    &.certStatusColor-7 {
      color: #707276;
      background: #ffffff;
      border: 1px solid #dbdce0;
    }
  }
  .el-table__column-filter-trigger {
    width: 20px;
    .el-icon-arrow-down {
      width: 20px;
      height: 20px;
      vertical-align: middle;
      background: url('~@/assets/image/personCenter/select.png') no-repeat
        center;
      background-size: 16px;
      cursor: pointer;
    }
    /*把icon的content更换了*/
    ::v-deep .el-icon-arrow-down:before {
      content: '';
    }
  }
  .pager-list {
    padding-top: 14px;
  }
}
</style>
